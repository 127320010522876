<template>
  <div class="subscriptions-basket">
    <div class="subscriptions">
      <div class="other-page">
        <div class="other-page-title">
          <h1>{{ $t('subscriptions.title') }}</h1>
          <p class="free-month">{{ $t('subscriptions.free_month') }}</p>
        </div>

        <div class="other-page-switch">
          <p :class="{ activeSubscription: !subscriptions.monthly }">
            {{ $t('subscriptions.price_m') }}
          </p>
          <el-switch
            class="switch"
            v-model="subscriptions.monthly"
            :disabled="disabledSwitchSubscriptionsPeriod"
            style="
              --el-switch-on-color: #2667ff;
              --el-switch-off-color: #555555;
            "
          />
          <p :class="{ activeSubscription: subscriptions.monthly }">
            {{ $t('subscriptions.price_a') }}
          </p>
        </div>
      </div>

      <div class="subscriptions-portfolio">
        <div class="description">
          <div class="title">
            <h3>Portfolio</h3>
            <p>{{ $t('subscriptions.complementary_module') }}</p>
          </div>
          <p class="portfolio-subtitle">
            {{ $t('subscriptions.portfolio_subtitle') }}
          </p>
          <div class="portfolio-advantage">
            <div>
              <img
                src="@/assets/svg/v2/home/goldenCheck.svg"
                alt="Golden check"
              />
              <p>{{ $t('subscriptions.advanced_gestion') }}</p>
            </div>
            <div>
              <img
                src="@/assets/svg/v2/home/goldenCheck.svg"
                alt="Golden check"
              />
              <p>{{ $t('subscriptions.advanced_stats') }}</p>
            </div>
          </div>
        </div>
        <div class="price-bloc">
          <img
            src="@/assets/svg/v2/background/portfolio.svg"
            alt="Portfolio background"
          />
          <div class="price">
            <h4>€{{ returnPortfolioPrice }}</h4>
            <p v-if="!subscriptions.monthly" class="per-month">
              {{ $t('subscriptions.per_month') }}
            </p>
            <p v-else class="per-month">{{ $t('subscriptions.per_year') }}</p>
            <p>{{ $t('subscriptions.included_vat') }}</p>
          </div>

          <!-- <button v-if="!disabledSubscription(portfolio)" @click="addPortfolioToCart()" class="choice-this-offer generic-btn">
          <p>{{ $t('subscriptions.choice_this_offer') }}</p>
        </button>
        <p v-else class="already-sub">{{ $t('subscriptions.already_sub') }}</p> -->

          <p class="already-sub">{{ $t('subscriptions.soon') }}</p>
        </div>
      </div>

      <div class="subscriptions-list">
        <div
          v-for="(subscription, n) in subscriptionsList"
          :key="n"
          class="card"
        >
          <img
            class="spirit-type"
            :src="dynamicPath(subscription.path)"
            :alt="subscription.label"
            width="58"
            height="58"
          />
          <img
            class="background"
            src="@/assets/svg/v2/home/background.svg"
            alt="TEP background"
          />
          <h3>{{ subscription.label }}</h3>

          <div class="price">
            <h4>€{{ returnSpiritPrice }}</h4>

            

            <div class="complementary">
              <p v-if="!subscriptions.monthly">
                {{ $t('subscriptions.per_month') }}
              </p>
              <p v-else>{{ $t('subscriptions.per_year') }}</p>
              <p> {{ $t('subscriptions.included_vat') }}</p>
            </div>
          </div>

          <div class="advantage">
            <div>
              <img src="@/assets/svg/v2/home/checked.svg" alt="Checked" />
              <p>{{ $t('subscriptions.references_access') }}</p>
            </div>
            <div>
              <img src="@/assets/svg/v2/home/checked.svg" alt="Checked" />
              <p>{{ $t('subscriptions.price_history') }}</p>
            </div>
            <div>
              <img src="@/assets/svg/v2/home/checked.svg" alt="Checked" />
              <p>{{ $t('subscriptions.advanced_stats') }}</p>
            </div>
          </div>
          <button
            v-if="!disabledSubscription(subscription)"
            class="generic-btn"
            @click="addItemToCart(subscription)"
          >
            {{ $t('subscriptions.add_to_cart') }}
          </button>
          <p v-else class="already-sub">
            {{ $t('subscriptions.already_sub') }}
          </p>
        </div>
      </div>

      <div class="cart">
        <div class="cart-count">
          <img src="@/assets/svg/v2/subscriptions/cart.svg" class="themed-img" alt="Cart" />
          <div class="count-bloc">
            <p>{{ cart.length }}</p>
          </div>
        </div>

        <p class="cart-title">{{ $t('subscriptions.cart') }}</p>

        <button @click="cartDrawer = true">
          {{ $t('subscriptions.see_cart') }}
        </button>
      </div>

      <el-drawer
        v-model="cartDrawer"
        :with-header="false"
        size="40%"
        modal-class="cart-drawer"
        style="min-width: 370px; max-width: 40%; width: 40%"
      >
        <div class="cart-drawer-content">
          <div class="cart-drawer-content-title">
            <h2>{{ $t('subscriptions.cart') }}</h2>
            <button @click="cartDrawer = false">
              <img src="@/assets/svg/v2/close.svg" alt="Close" class="themed-img"/>
            </button>
          </div>

          <h3 class="premium">{{ $t('settings.subscriptions.premium') }}</h3>

          <div class="cart-drawer-subscriptions">
            <div v-for="(item, n) in cart" :key="n" class="subscriptions-items">
              <div class="item-description">
                <img
                  class="spirit-type"
                  :src="dynamicPath(item.path)"
                  :alt="item.label"
                  width="58"
                  height="58"
                />

                <div>
                  <h3>{{ item.label }}</h3>
                  <p v-if="item.duration === 'm'">
                    {{ $t('subscriptions.month') }}
                  </p>
                  <p v-else>{{ $t('subscriptions.year') }}</p>
                </div>
              </div>

              <div class="item-price">
                <h3>{{ item.price }}€</h3>
                <button @click="removeItem(item)">
                  {{ $t('subscriptions.delete') }}
                </button>
              </div>
            </div>
          </div>

          <div class="cart-drawer-subscriptions-infos">
            <div>
              <h4>{{ $t('subscriptions.sub_total') }}</h4>
              <p>{{ totalCart }}€</p>
            </div>
          </div>

          <el-divider />

          <div class="cart-drawer-total">
            <div>
              <h4>{{ $t('subscriptions.total') }}</h4>
              <p>{{ $t('subscriptions.include_vat') }}</p>
            </div>
            <p>{{ totalCart }}€</p>
          </div>

          <button
            v-if="registerStep > 2"
            @click="toPaymentProcess()"
            class="generic-btn process-payment-btn"
          >
            {{ $t('subscriptions.payment_process') }}
          </button>
          <div
            v-else
            @click="$router.push('/register')"
            class="registration-progress"
          >
            <div>
              <div class="registration-progress-explain">
                {{ $t('register.end_register') }}
                <img src="@/assets/svg/v2/arrowToRight.svg" alt="Right arrow" class="themed-img"/>
              </div>
              <div>
                {{ $t('register.verify_account') }}
              </div>
            </div>
            <div>
              <img
                v-if="registerStep === 1"
                src="@/assets/svg/v2/accountStep/2.svg"
                class="themed-img"
                alt="Confirm email & phone"
              />
              <img
                v-if="registerStep === 2"
                src="@/assets/svg/v2/accountStep/3.svg"
                class="themed-img"
                alt="KYC"
              />
              <img
                v-if="registerStep === 3"
                src="@/assets/svg/v2/accountStep/4.svg"
                class="themed-img"
                alt="Banking informations"
              />
            </div>
          </div>
        </div>
      </el-drawer>

    </div>

    <div class="cart-drawer-content computer-cart">
      <div class="cart-drawer-content-title">
        <h2>{{ $t('subscriptions.cart') }}</h2>
      </div>

      <h3 class="premium">{{ $t('settings.subscriptions.premium') }}</h3>

      <div class="cart-drawer-subscriptions">
        <div v-for="(item, n) in cart" :key="n" class="subscriptions-items">
          <div class="item-description">
            <img
              class="spirit-type"
              :src="dynamicPath(item.path)"
              :alt="item.label"
              width="58"
              height="58"
            />

            <div>
              <h3>{{ item.label }}</h3>
              <p v-if="item.duration === 'm'">
                {{ $t('subscriptions.month') }}
              </p>
              <p v-else>{{ $t('subscriptions.year') }}</p>
            </div>
          </div>

          <div class="item-price">
            <h3>{{ item.price }}€</h3>
            <button @click="removeItem(item)">
              {{ $t('subscriptions.delete') }}
            </button>
          </div>
        </div>
      </div>

      <div class="cart-drawer-subscriptions-infos">
        <div>
          <h4>{{ $t('subscriptions.sub_total') }}</h4>
          <p>{{ totalCart }}€</p>
        </div>
      </div>
      <el-divider />

      <div class="cart-drawer-total">
        <div>
          <h4>{{ $t('subscriptions.total') }}</h4>
          <p>{{ $t('subscriptions.include_vat') }}</p>
        </div>
        <p>{{ totalCart }}€</p>
      </div>

      <button
        v-if="registerStep > 2"
        @click="toPaymentProcess()"
        class="generic-btn process-payment-btn"
      >
        {{ $t('subscriptions.payment_process') }}
      </button>
      <div
        v-else
        @click="$router.push('/register')"
        class="registration-progress"
      >
        <div>
          <div class="registration-progress-explain">
            {{ $t('register.end_register') }}
            <img src="@/assets/svg/v2/arrowToRight.svg" alt="Right arrow" class="themed-img"/>
          </div>
          <div>
            {{ $t('register.verify_account') }}
          </div>
        </div>
        <div>
          <img
            v-if="registerStep === 1"
            src="@/assets/svg/v2/accountStep/2.svg"
            class="themed-img"
            alt="Confirm email & phone"
          />
          <img
            v-if="registerStep === 2"
            src="@/assets/svg/v2/accountStep/3.svg"
            class="themed-img"
            alt="KYC"
          />
          <img
            v-if="registerStep === 3"
            src="@/assets/svg/v2/accountStep/4.svg"
            class="themed-img"
            alt="Banking informations"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { findIndex, find } from 'lodash'
import { ElMessage } from 'element-plus'
import m from 'moment'

export default {
  data() {
    return {
      cartDrawer: false,

      subscriptions: {
        monthly: false,
        individual: true,
      },

      portfolio: {
        label: 'Portfolio',
        path: 'portfolio.svg',
        id: 7,
      },

      subscriptionsList: [
        {
          label: 'Rhums',
          path: 'rhum_ron_rum.svg',
          id: 0,
        },
        {
          label: 'Eau de vie',
          path: 'tequila.svg',
          id: 3,
        },
        {
          label: 'Whisky',
          path: 'whiskey_whisky.svg',
          id: 1,
        },
        {
          label: 'Agave, Distillate & Various',
          path: 'cognac.svg',
          id: 5,
        },
        {
          label: 'American Whisky',
          path: 'bourbon_rye.svg',
          id: 2,
        },
        {
          label: 'Liqueur & Aperitif',
          path: 'liquors.svg',
          id: 4,
        },
      ],

      cart: [],
      disabledSwitchSubscriptionsPeriod: false,
    }
  },
  watch: {
    totalCart() {
      if (this.cart.length > 0) {
        this.disabledSwitchSubscriptionsPeriod = true
      } else {
        this.disabledSwitchSubscriptionsPeriod = false
      }
    },
  },
  computed: {
    ...mapGetters({
      getAuthStatus: 'getIsAuth',
      userSubscriptions: 'getSubscriptions',
      registerStep: 'registerStep',
    }),
    totalCart() {
      let total = 0
      this.cart.forEach((item) => {
        total += item.price
      })
      return parseFloat(total).toFixed(2)
    },
    returnSpiritPrice() {
      if (this.subscriptions.individual) {
        if (this.subscriptions.monthly) {
          return 65.89
        } else {
          return 5.99
        }
      } else {
        if (this.subscriptions.monthly) {
          return 109.89
        } else {
          return 9.99
        }
      }
    },
    returnPortfolioPrice() {
      if (this.subscriptions.individual) {
        if (this.subscriptions.monthly) {
          return 131.89
        } else {
          return 11.99
        }
      } else {
        if (this.subscriptions.monthly) {
          return 219.89
        } else {
          return 19.99
        }
      }
    },
  },
  methods: {
    ...mapActions({
      getSubscriptions: 'getSubscriptions',
    }),
    disabledSubscription(item) {
      if (this.getAuthStatus) {
        const findUserSubscription = find(this.userSubscriptions, {
          type: item.id,
        })
        if (findUserSubscription && findUserSubscription.subscription_term) {
          if (
            m().format('YYYY-MM-DD HH:mm:ss') <
            m(
              findUserSubscription.subscription_term,
              'YYYY-MM-DD HH:mm:ss'
            ).format('YYYY-MM-DD HH:mm:ss')
          ) {
            return true
          }
        } else {
          return false
        }
      }
    },
    addItemToCart(item) {
      const type = this.subscriptions.individual ? 'individual' : 'pro'
      const duration = this.subscriptions.monthly ? 'a' : 'm'

      item.type = type
      item.duration = duration
      item.price = this.returnSpiritPrice

      const index = findIndex(this.cart, (e) => {
        return e.label === item.label
      })

      if (index === -1) {
        this.cart.push(item)
        localStorage.setItem('subscriptionsCart', JSON.stringify(this.cart))
      } else {
        ElMessage({
          message: this.$t('subscriptions.already_in_cart'),
          type: 'warning',
          grouping: true,
        })
      }
    },
    addPortfolioToCart() {
      const type = this.subscriptions.individual ? 'individual' : 'pro'
      const duration = this.subscriptions.monthly ? 'a' : 'm'
      let tempPortfolio = this.portfolio

      tempPortfolio.type = type
      tempPortfolio.id = 7
      tempPortfolio.duration = duration
      tempPortfolio.price = this.returnPortfolioPrice

      const index = findIndex(this.cart, (e) => {
        return e.label === this.portfolio.label
      })

      if (index === -1) {
        this.cart.push(tempPortfolio)
        localStorage.setItem('subscriptionsCart', JSON.stringify(this.cart))
      } else {
        ElMessage({
          message: this.$t('subscriptions.already_in_cart'),
          type: 'warning',
          grouping: true,
        })
      }
    },
    removeItem(item) {
      const index = findIndex(this.cart, (e) => {
        return e.label === item.label
      })
      this.cart.splice(index, 1)
      localStorage.setItem('subscriptionsCart', JSON.stringify(this.cart))
    },
    redirect(path) {
      this.$router.push(path)
    },
    dynamicPath(path) {
      return require(`@/assets/svg/v2/categories/${path}`)
    },
    removeCart(full) {
      localStorage.removeItem('subscriptionsCart')

      if (full) {
        this.$router.push('/')
      }
    },
    toPaymentProcess() {
      if (this.cart.length > 0) {
        this.$router.push('/subscriptions/payment')
      } else {
        ElMessage({
          message: this.$t('subscriptions.cart_empty'),
          type: 'warning',
          grouping: true,
        })
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0)

    const type = this.$route.params.type
    const duration = this.$route.params.duration || 'm'

    if (type === 'individual') {
      this.subscriptions.individual = true
    } else if (type === 'pro') {
      this.subscriptions.individual = false
    } else {
      this.removeCart(true)
    }

    if (duration === 'm') {
      this.subscriptions.monthly = false
    } else if (duration === 'a') {
      this.subscriptions.monthly = true
    } else {
      this.removeCart(true)
    }

    const saveLS = localStorage.getItem('subscriptionsCart')

    if (saveLS) {
      this.cart = JSON.parse(saveLS)

      if (this.cart) {
        if (this.cart[0].duration === 'a') {
          this.subscriptions.monthly = true
          this.disabledSwitchSubscriptionsPeriod = true
        } else {
          this.subscriptions.monthly = false
          this.disabledSwitchSubscriptionsPeriod = true
        }
      }
    }

    if (this.getAuthStatus) {
      this.getSubscriptions()
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.subscriptions-basket {
  display: flex;
  flex-direction: row;
}

.computer-cart {
  margin-right: 40px;
  margin-top: 50px;
  max-width: 450px;
  flex: 1;
}

.pricing-user-type,
.spirits-types-list,
.spirits-types-list div,
.spirits-types-list-1,
.subscriptions-list,
.card,
.cart,
.cart-count,
.spirits-types-list-2,
.subscriptions-list,
.price,
.advantage div,
.portfolio-advantage,
.subscriptions-portfolio,
.price-bloc,
.other-page,
.other-page-switch,
.registration-progress,
.registration-progress-explain {
  display: flex;
}

.subscriptions {
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 40px;
  flex: 1;

  .other-page {
    justify-content: space-between;
    align-items: end;

    .other-page-title {
      h1 {
        margin-bottom: 6px;
      }
    }

    .other-page-switch {
      align-items: center;
      color: var( --subtitle-text-color);

      .switch {
        margin-bottom: 0;
        margin: 0 10px;
      }
    }
  }

  .subscriptions-list {
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    margin-bottom: 40px;
    display: flex;
    gap: 20px;

    .card {
      position: relative;
      min-width: 350px;
      max-width: 382px;
      height: 370px;
      border-radius: 20px;
      border: 1px solid var(--border-1);
      background: var(--background-color-2);
      flex-direction: column;
      align-items: center;
      flex: 1;

      .spirit-type {
        margin-top: 20px;
        margin-bottom: 15px;
        z-index: 1;
      }

      .background {
        position: absolute;
        top: -45px;
        width: 350px;
        z-index: 0;
      }

      h3 {
        color: var(--text-color);
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .price {
        margin-bottom: 26px;

        h4,
        .tax {
          color: var(--text-color);
          font-size: 24px;
          font-weight: 500;
        }

        // .tax {
        //   color: var(--text-color);
        //   margin-left: 3px;
        //   font-size: 12px;
        // }

        .complementary {
          color: var(--text-color);
          max-width: 159px;
          margin-left: 7px;

          p {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 2px;
          }
        }
      }

      .advantage {
        div {
          margin-top: 10px;

          img {
            margin-right: 8px;
          }

          p {
            color: var(--subtitle-text-color);
            font-size: 15px;
            font-weight: 500;
          }
        }
      }

      button {
        margin-top: 26px;
        width: 197px;
      }
    }
  }
}

.cart {
  position: fixed;
  align-items: center;
  bottom: 5px;
  right: 40%;
  border-radius: 60px;
  border: 1px solid var(--border);
  background: var(--background-color-3);
  box-shadow: 0px 4px 144px 0px rgba(0, 0, 0, 0.55);
  width: 260px;
  height: 59px;
  z-index: 2;
  display: none;
  left: 50%;
  transform: translate(-50%, -50%);

  .cart-count {
    margin-left: 18px;
    position: relative;

    div {
      position: absolute;
      left: 10px;
      top: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 27px;
      background: $primary-color;
      width: 21px;
      height: 21px;

      p {
        font-size: 14px;
        color: var(--text-color);
        font-weight: 600;
      }
    }
  }

  .cart-title {
    color: var(--text-color);
    font-size: 16px;
    font-weight: 600;
    margin-left: 15px;
  }

  button {
    background: transparent;
    border: 0;
    color: var(--light-blue);
    font-size: 15px;
    font-weight: 600;
    margin-left: 77px;
  }
}

.cart-drawer {
  background: var(--background-color);
}

.cart-drawer-content {
  .cart-drawer-content-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 39px;
    margin-top: 20px;

    h2 {
      color: var(--text-color);
      font-size: 24px;
      font-weight: 600;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 0;
      border-radius: 76px;
      border: 1px solid var(--border);
      background: var(--background-color-3);
      height: 38px;
      width: 38px;
    }
  }

  .premium {
    font-family: Hyperwave;
    color: var(--text-color);
    font-size: 22px;
    font-weight: 700;
  }

  .cart-drawer-subscriptions {
    margin-top: 36px;

    .subscriptions-items {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      align-items: center;

      .item-description {
        display: flex;
        align-items: center;

        img {
          margin-right: 16px;
        }

        div {
          h3 {
            color: var(--text-color);
            font-size: 18px;
            font-weight: 600;
          }

          p {
            color: var(--subtitle-text-color);
            font-size: 15px;
            font-weight: 500;
          }
        }
      }

      .item-price {
        display: flex;
        flex-direction: column;
        align-items: end;

        h3 {
          color: var(--text-color);
          font-size: 16px;
          font-weight: 600;
        }

        button {
          background: transparent;
          border: 0;
          color: var(--subtitle-text-color);
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }

  .cart-drawer-subscriptions-infos {
    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      h4 {
        color: var(--subtitle-text-color);
        font-size: 16px;
        font-weight: 500;
      }

      p {
        color: var(--text-color);
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .cart-drawer-total {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 42px;

    div {
      h4 {
        color: var(--subtitle-text-color);
        font-size: 20px;
        font-weight: 500;
      }

      p {
        color: var(--subtitle-2-text-color);
        font-size: 14px;
        font-weight: 500;
      }
    }

    p {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 500;
    }
  }

  .generic-btn {
    margin: auto;
  }
}

.subscriptions-portfolio {
  border-radius: 12px;
  border: 1px solid var(--border-1);
  background: var(--background-color-2);
  padding: 24px;
  margin:  36px auto 24px ;
  justify-content: space-between;

  .description {
    .title {
      display: flex;
      margin-bottom: 7px;

      h3 {
        color: var(--text-color);
        font-size: 18px;
        font-weight: 600;
      }

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--subtitle-text-color);
        border-radius: 6px;
        background: var(--background-color-7);
        width: 174px;
        height: 25px;
        margin-left: 18px;
        font-size: 14px;
      }
    }

    .portfolio-subtitle {
      max-width: 397px;
      color: var(--subtitle-text-color);
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 18px;
    }

    .portfolio-advantage {
      div {
        display: flex;
        margin-right: 14px;

        img {
          margin-right: 8px;
        }

        p {
          color: var(--text-color);
          font-size: 15px;
          font-weight: 500s;
        }
      }
    }
  }

  .price-bloc {
    position: relative;
    flex-direction: column;
    justify-content: space-between;

    img {
      position: absolute;
      z-index: 0;
      top: -25px;
      right: -40px;
    }

    .price {
      h4 {
        color: var(--text-color);
        font-size: 22px;
        font-weight: 600;
        margin-right: 4px;
      }

      .per-month,
      p {
        color: var(--text-color);
        font-size: 15px;
        font-weight: 600;
        margin-left: 5px;
        margin-top: 5px;
      }
    }

    .choice-this-offer {
      z-index: 1;
      width: 197px;
    }
  }
}

.already-sub {
  margin-top: 30px;
  color: var(--buysuccess-color);
  font-size: 15px;
  font-weight: 500;
}
.sub-soon {
  margin-top: 40px;
  color: var(--buysuccess-color);
  font-size: 15px;
  font-weight: 500;
}

.free-month {
  color: var(--buysuccess-color);
}

.activeSubscription {
  color: $primary-color !important;
}

.activeType {
  background: var(--background-color-7) !important;
  color: var(--text-color) !important;
  font-weight: 600 !important;
}

.registration-progress {
  cursor: pointer;
  color: var(--text-color);
  align-items: center;
  justify-content: space-between;
  background-color: $primary-color;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 0 auto;

  div {
    .registration-progress-explain {
      align-items: center;
      margin-bottom: 7px;
      margin-top: 2px;
      margin-right: 10px;
    }

    div:nth-child(2) {
      font-size: 14px;
      color: var(--subtitle-text-color);
    }
  }
}

.process-payment-btn {
  max-width: 250px;
}

.el-drawer {
  border: 1px solid var(--border);
  background: var(--background-color-2);
}

@media only screen and (max-width: 1300px) {
  .cart {
    display: flex;
  }

  .computer-cart {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .subscriptions {
    margin: 40px 10px;
  }

  .subscriptions-portfolio {
    flex-wrap: wrap;
    justify-content: center;
    max-width: 280px;
    margin: 20px auto;

    .price-bloc {
      display: flex;
      align-items: center;
      margin-top: 15px;
    }

    .description {
      .title {
        justify-content: space-between;
        margin-bottom: 15px;
      }
    }
  }

  .subscriptions-list {
    justify-content: center !important;

    .card {
      width: 100%;
      min-width: 330px !important;
      max-width: 330px;
    }
  }
}
</style>
